import React, { useEffect, useState } from 'react';
import CommentList from './CommentList';
import AddComment from './AddComment';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';

const CommentSection = ({ postId, convoUserId, setPassAlert, collapsed, setCollapsed }) => {
  const [comments, setComments] = useState([]);
  const [sortOption, setSortOption] = useState('recent');
  const [profile, setProfile] = useState(false);
  const [showBlink, setShowBlink] = useState(false);

  useEffect(() => {
    onAuthStateChanged(firebase_auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setProfile({ ...user, token });
        } catch (error) {
          console.error('Error retrieving ID token:', error);
        }
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  const handleAddComment = (newComment) => {
    const id = Date.now();
    setComments([
      ...comments,
      {
        id: id,
        content: newComment,
        author: profile?.displayName,
        likes: 0,
        time: new Date().toISOString(),
        userId: profile?.uid,
        op_id: convoUserId,
        replies: [],
      },
    ]);
    addCommentAsync(newComment, id);
  };

  // Trigger the bounce animation once after a delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBlink(true); // Enable bounce animation
      const removeAnimation = setTimeout(() => {
        setShowBlink(false); // Remove bounce class after animation
      }, 1000); // Match the animation duration
      return () => clearTimeout(removeAnimation);
    }, 2000); // Delay for 2 seconds after page load
    return () => clearTimeout(timeout);
  }, []);

  const getCommentsAsync = async () => {
    try {
      let comments = [];
      const res = await fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getComments/${postId}`);
      const data = await res.json();

      if (data?.comments?.length) {
        const commentsWithReplies = await Promise.all(
          data.comments.map(async (comment) => {
            const repliesRes = await fetch(
              `https://aiarchives-375517.uc.r.appspot.com/api/getReplies/${postId}/${comment.commentID}`
            );
            const replies = await repliesRes.json();
            replies.replies = replies.replies.map((reply) => ({
              ...reply,
              content: reply.text,
              time: reply.createdAt,
              author: reply.user,
            }));
            return {
              id: comment.commentID,
              content: comment.text,
              author: comment.user,
              likes: comment.likes,
              userId: comment.userId,
              op_id: comment.op_id,
              time: comment.createdAt,
              replies: replies.replies || [],
            };
          })
        );

        comments = commentsWithReplies;
      }
      setComments(comments);
    } catch (error) {
      console.error('Error fetching comments or replies:', error);
    }
  };

  useEffect(() => {
    getCommentsAsync();
  }, []);

  const addCommentAsync = (newComment, id) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/addComment/${postId}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ text: newComment }),
    })
      .then(async (res) => {
        const data = await res.json();
        console.log(data);
        setComments((prev) =>
          prev.map((comment) => (comment.id === id ? { ...comment, id: data.commentId } : comment))
        );
        setPassAlert('Comment added successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to add comment. Please try again.');
      });
  };

  const handleSort = (option) => {
    setSortOption(option);
  };

  const sortedComments =
    sortOption === 'recent'
      ? [...comments].sort((a, b) => new Date(b.time) - new Date(a.time))
      : [...comments].sort((a, b) => b.votes - a.votes);

  return (
    <div className='z-[9] fixed h-[100vh] top-[67px] right-0 flex'>
      <div
        className={`self-start text-black font-bold py-1 px-2 rounded-l-lg cursor-pointer text-center mt-4 ${
          showBlink ? 'blink' : 'bg-white '
        }`}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? (
          <>
            <span className='text-xl px-1'>&#171;</span>
            <span className='text-md'>Comment</span>
          </>
        ) : (
          <>
            <span className='text-md'>Close</span>
            <span className='text-xl px-1'>&#187;</span>
          </>
        )}
      </div>

      {!collapsed && (
        <div
          className='bg-[#f0f0ef] p-4 overflow-auto flex flex-col items-center w-full h-full top-[60px] fixed left-0 z-50 
      lg:w-[420px] lg:top-0 lg:static lg:rounded-md'
          style={{ border: '1px solid rgb(221, 221, 221)', boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px' }}
        >
          <div
            className='block lg:hidden self-start bg-white text-black font-bold py-1 px-2 rounded-r-lg cursor-pointer text-center mt-4'
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed && (
              <>
                <span className='text-md'>Close</span>
                <span className='text-xl px-1'>&#187;</span>
              </>
            )}
          </div>
          {!profile?.uid && <span className='text-md text-gray-900'>You need to Sign in to comment</span>}
          <div className='flex justify-between items-center w-full'>
            <span className='text-sm text-gray-600'>
              {comments.length} Comment{comments.length !== 1 && 's'}
            </span>
            <select
              className='border rounded-md p-1 text-sm'
              value={sortOption}
              onChange={(e) => handleSort(e.target.value)}
            >
              <option value='recent'>Sort by Recent</option>
              <option value='upvotes'>Sort by Upvotes</option>
            </select>
          </div>
          {profile?.uid && <AddComment onAdd={handleAddComment} />}
          <CommentList
            postId={postId}
            convoUserId={convoUserId}
            setPassAlert={setPassAlert}
            comments={sortedComments}
            setComments={setComments}
          />
        </div>
      )}
    </div>
  );
};

export default CommentSection;
