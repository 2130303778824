import React from 'react';
import { Info, Linkedin } from 'lucide-react';

const AnnouncementBanner = () => {
  return (
    <div className="bg-[#e6e6e6] border-l-4 border-[#0A369D] pt-1 px-6 pb-6 my-4 max-w-3xl mx-auto rounded-lg shadow-md">
      <div className="flex items-center mb-1">
        <Info className="text-[#0A369D] mr-3" size={28} />
        <h1 className="text-2xl font-bold text-[#0A369D]">New Features Updates</h1>
      </div>

      <div className="space-y-4">
        <div className="bg-white p-4 border border-[#0A369D] rounded-md shadow-sm">
          <h2 className="text-lg font-semibold text-[#0A369D] mb-1">
            Comments on Posts <span className="text-sm font-normal text-gray-500">| Nov. 27th 2024</span>
          </h2>
          <p className="text-gray-700 text-sm">
            We have added a new Comment feature to enhance your interaction with Posts.
          </p>
        </div>
        <div className="bg-white p-4 border border-[#0A369D] rounded-md shadow-sm">
          <h2 className="text-lg font-semibold text-[#0A369D] mb-1">
            Share Buttons <span className="text-sm font-normal text-gray-500">| Nov. 3rd 2024</span>
          </h2>
          <p className="text-gray-700 text-sm">
            Share buttons are now conveniently located directly within the extension.
          </p>
        </div>
        <div className="flex items-center space-x-4 mt-4">
          <a
            href="https://www.youtube.com/watch?v=wwzEIJDeoPM"
            target="_blank"
            rel="noopener noreferrer"
            className="px-5 py-3 bg-[#0A369D] text-white font-bold rounded-lg shadow-md hover:bg-[#08306b] transition-transform transform hover:scale-105 no-underline"
          >
            Watch Tutorial Video
          </a>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBanner;
