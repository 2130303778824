import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col } from "reactstrap";
import { db } from "../../firestore.js";
import { getDoc, doc } from "firebase/firestore";
import { collection, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import "../../App.scss";
import { LoadingAnimation } from "../../components/Animations/Animations";
import HomeHeader from "../../components/Headers/HomeHeader";
import Card from "../../components/Cards/Card";
import Chat from "../Chat/Chat";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ButtonGroupComponent from "../../components/ButtonGroup/ButtonGroup";
import ButtonColumnComponent from "../../components/ButtonColumn/ButtonColumn";
import { Divider } from "@mui/material";
import marketwatchlogo from "../../assets/images/marketwatchlogo.png";
import mitlogo from "../../assets/images/mitlogo.png";
import bezingalogo from "../../assets/images/bezingalogo.png";
import aplogo from "../../assets/images/aplogo.png";
import digitaljournallogo from "../../assets/images/digitaljournallogo.png";
import chicagomanuallogo from "../../assets/images/chicagomanual.png";
import AnnouncementBanner from "./Announcement.js";

const Home = () => {
  let navigate = useNavigate();

  const [postIds, setPostIds] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [trending, setTrending] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [randomPosts, setRandomPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [endInfiniteScrl, setEndInfiniteScrl] = useState(true);
  const [lastVisible, setLastVisible] = useState(false);
  const [lastTopVisible, setTopLastVisible] = useState(false);
  const [displayedPostIds, setDisplayedPostIds] = useState(new Set());
  const [activeButton, setActiveButton] = useState(2);
  const [checkboxState, setCheckboxState] = useState({
    chatgpt3: true,
    chatgpt4: true,
    bard: true,
    claude: true,
    meta: true,
  });
  const [isTopPostFirstLoading, setIsTopPostsFirstLoading] = useState(false);
  const [noFiltersActive, setNoFiltersActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getRecentPosts = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    let q;
    if (lastVisible) {
      q = query(collection(db, "posts"), orderBy("created", "desc"), startAfter(lastVisible), limit(10));
    } else {
      q = query(collection(db, "posts"), orderBy("created", "desc"), limit(10));
    }

    try {
      const result = await getDocs(q);

      // Create an array of promises to get user data for each post
      const userPromises = result.docs.map((postDoc) =>
        getDoc(doc(db, "users", postDoc.data()?.userid)).then((userDocSnap) => ({
          title: postDoc.data().title,
          id: postDoc.id,
          tags: postDoc.data().tags,
          views: postDoc.data().views,
          photoURL: userDocSnap.data().photoURL,
          created: postDoc.data().created,
          relatedPosts: postDoc.data().relatedPosts || [],
          suggestedPosts: postDoc.data().suggestedPosts || [],
        }))
      );

      // Use Promise.all to resolve all promises in parallel
      const res = await Promise.all(userPromises);

      setTrending((prev) => [...prev, ...res]);
      setLastVisible(result.docs[result.docs.length - 1]);
      setEndInfiniteScrl(!!res.length);
    } catch (error) {
      // Handle error here
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTopPosts = async () => {
    let q;
    const res = [];
    fetch("https://aiarchives-375517.uc.r.appspot.com/api/getTopPosts/")
      .then((response) => response.json())
      .then((data) => {
        const posts = data.topPosts;

        if (lastTopVisible) {
          const nextTenPosts = posts.slice(lastTopVisible + 1, lastTopVisible + 11);
          q = nextTenPosts;

          setTopLastVisible(lastTopVisible + 10);
        } else {
          // If lastTopVisible doesn't exist, get the first 10 posts
          q = posts.slice(0, 10);
          setTopLastVisible(10);
        }

        for (const postDoc of q) {
          res.push({
            title: postDoc.title,
            id: postDoc.postId,
            tags: postDoc.tags,
            views: postDoc.views,
            created: postDoc.created,
            photoURL: postDoc.photoURL,
            relatedPosts: postDoc.relatedPosts || [],
            suggestedPosts: postDoc.suggestedPosts || [],
          });
        }

        setTopPosts((prev) => [...prev, ...res]);
        setEndInfiniteScrl(!!res.length);
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => {
        setIsTopPostsFirstLoading(false);
      });
  };

  useEffect(() => {
    getRecentPosts();
    getTopPosts();
    getPostIds();
  }, []);

  useEffect(() => {
    let filteredPosts = [];
    const noFiltersActive =
      !checkboxState.chatgpt3 &&
      !checkboxState.chatgpt4 &&
      !checkboxState.bard &&
      !checkboxState.claude &&
      !checkboxState.meta;
    setNoFiltersActive(noFiltersActive); // Remember to declare this state variable at the beginning of your component

    if (!noFiltersActive) {
      if (activeButton === 1) {
        filteredPosts = trending.filter(
          (post) =>
            (checkboxState.chatgpt3 && post.tags[0] === "ChatGPT") ||
            (checkboxState.chatgpt4 && post.tags[0] === "ChatGPT-4") ||
            (checkboxState.bard && post.tags[0] === "Bard") ||
            (checkboxState.claude && post.tags[0] === "Claude") ||
            (checkboxState.meta && post.tags[0] === "Meta")
        );
      } else if (activeButton === 3) {
        filteredPosts = randomPosts.filter(
          (post) =>
            (checkboxState.chatgpt3 && post.tags[0] === "ChatGPT") ||
            (checkboxState.chatgpt4 && post.tags[0] === "ChatGPT-4") ||
            (checkboxState.bard && post.tags[0] === "Bard") ||
            (checkboxState.claude && post.tags[0] === "Claude") ||
            (checkboxState.meta && post.tags[0] === "Meta")
        );
      } else {
        filteredPosts = topPosts.filter(
          (post) =>
            (checkboxState.chatgpt3 && post.tags[0] === "ChatGPT") ||
            (checkboxState.chatgpt4 && post.tags[0] === "ChatGPT-4") ||
            (checkboxState.bard && post.tags[0] === "Bard") ||
            (checkboxState.claude && post.tags[0] === "Claude") ||
            (checkboxState.meta && post.tags[0] === "Meta")
        );
      }
    }

    setFilteredPosts(filteredPosts);
  }, [activeButton, checkboxState, topPosts.length, trending.length, randomPosts.length]);

  const getPostIds = async () => {
    const postIdsRef = doc(db, "util", "postCount");
    const snapshot = await getDoc(postIdsRef);
    setPostIds(snapshot.data().postIdList);
    setPostCount(snapshot.data().postCount);
  };

  useEffect(() => {
    getRandomPosts();
  }, [postCount, postIds.length]);

  const handleOnSearch = (string, _) => {};

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    navigate(`/id/${item.postId}`);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return <span style={{ cursor: "pointer" }}>{item.title}</span>;
  };

  function getRandomPostIds(numPosts = 10) {
    const newRandomPostIds = [];
    if (postCount) {
      while (newRandomPostIds.length < numPosts) {
        const randomIndex = Math.floor(Math.random() * postCount);
        const randomId = postIds[randomIndex];

        if (!displayedPostIds.has(randomId)) {
          setDisplayedPostIds((previousState) => new Set([...previousState, randomId]));
          newRandomPostIds.push(randomId);
        }
      }
    }

    return newRandomPostIds;
  }

  const [isRandomLoading, setIsRandomLoading] = useState(false);

  const getRandomPosts = async () => {
    // If it's already loading, do not start another fetch
    if (isRandomLoading) {
      return;
    }

    setIsRandomLoading(true);

    // Calculate the random start index
    const randomPostIds = getRandomPostIds();
    const randomPosts = [];

    if (randomPostIds.length === 0) {
      setIsRandomLoading(false);
      return [];
    }

    try {
      const postPromises = randomPostIds.map(async (postId) => {
        const postRef = doc(db, "posts", postId);
        const docSnap = await getDoc(postRef);
        if (docSnap.exists()) {
          const postDoc = docSnap.data();
          const userRef = doc(db, "users", postDoc?.userid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            return {
              title: postDoc.title,
              id: postId,
              tags: postDoc.tags,
              views: postDoc.views,
              photoURL: userData.photoURL,
              created: postDoc.created,
              relatedPosts: postDoc.relatedPosts || [],
              suggestedPosts: postDoc.suggestedPosts || [],
            };
          }
        }
      });

      const fetchedPosts = await Promise.all(postPromises);
      // Filter out any undefined elements (occurs when docSnap or userSnap doesn't exist)
      const validPosts = fetchedPosts.filter(Boolean);

      // Fisher-Yates shuffle algorithm
      for (let i = validPosts.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [validPosts[i], validPosts[j]] = [validPosts[j], validPosts[i]];
      }

      setRandomPosts((prev) => [...prev, ...validPosts]);
      setEndInfiniteScrl(!!randomPostIds.length);
    } catch (error) {
      // Handle error here
      console.error(error);
    } finally {
      setIsRandomLoading(false);
    }
  };

  return (
    <div className="Home">
      <HomeHeader />
      <div className="container">
        <div className="content row">
          <div className="submit-box col-11 col-lg-8" style={{ borderRadius: "10px" }}>
            <h1 style={{ margin: "15px 20px" }}>Questions by Humans, Answers by A.I.</h1>
            <h2 style={{ margin: "15px 20px", fontWeight: "normal" }}>
              Your reliable tool for citing Generative A.I. conversations. Easily save discussions with Gemini, ChatGPT,
              Meta and Claude into a URL.
            </h2>
            <h2 style={{ margin: "15px 20px", fontWeight: "normal" }}>
              🔍 Why is citing AI more crucial than you think?{" "}
              <a
                href="https://medium.com/@kcao0228/how-to-cite-chatgpt-use-a-i-archives-bc254cb006d1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue" }}
              >
                Discover now!
              </a>
            </h2>
            <br/>
            <AnnouncementBanner/>
            <br/>
            <h2
              style={{
                margin: "15px 20px",
                fontSize: "17px",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;1.</span>
              Download the Chrome Extension by clicking on the 'Get Extension' button.
            </h2>
            <h2
              style={{
                margin: "15px 20px",
                fontSize: "17px",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;2.</span>
              Hover over the 'How to Use' button and click 'Start'!
            </h2>
            <h2
              style={{
                margin: "15px 20px",
                fontSize: "17px",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;3.</span>
              Have a conversation with ChatGPT, Gemini, Meta, or Claude and use the generated URL to share and create
              citations.
            </h2>
            <h2
              style={{
                margin: "15px 20px",
                fontSize: "17px",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;4.</span>
              Organize and view your saved and previous posts in the dashboard by signing in on the top right!
            </h2>
            <h2
              style={{
                margin: "15px 20px",
                fontSize: "17px",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <span style={{ marginRight: "10px" }}>&nbsp;&nbsp;&nbsp;&nbsp;5.</span>
              We welcome your feedback, connect with us on LinkedIn:
              <a
                href="https://www.linkedin.com/in/kuncaoml/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: "5px", textDecoration: "underline", color: "blue" }}
              >
                Kun
              </a>
              &nbsp;&&nbsp;
              <a
                href="https://www.linkedin.com/in/aliajam/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "blue" }}
              >
                Ali
              </a>
            </h2>
            <ButtonColumnComponent />
            <Col md="8" sm="12" className="search" style={{ margin: "5px" }}></Col>
          </div>
        </div>
        <div className="steps row">
          <div style={{ padding: "10px", margin: "auto" }} className="col-11 col-lg-8">
            <Divider style={{ fontSize: "18px", marginTop: "25px" }}>Featured In</Divider>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "5px",
              }}
            >
              <a
                href="https://www.digitaljournal.com/pr/news/getnews/a-i-archives-is-making-it-possible-for-students-researchers-to-accurately-cite-chatgpt-and-bard"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={digitaljournallogo}
                  alt="featuredImage4"
                  style={{ width: "120px", height: "auto", margin: "10px" }}
                />
              </a>
              <a
                href="https://www.chicagomanualofstyle.org/qanda/data/faq/topics/Documentation/faq0422.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={chicagomanuallogo}
                  alt="featuredImage5"
                  style={{ width: "65px", height: "auto", margin: "10px" }}
                />
              </a>
              <a
                href="https://apnews.com/press-release/ein-presswire-newsmatics/science-atlanta-ein-presswire-newsmatics-c4a08c43555229766eaf318e78ec9f7a"
                target="_blank"
                rel="noreferrer"
              >
                <img src={aplogo} alt="featuredImage3" style={{ width: "50px", height: "auto", margin: "10px" }} />
              </a>
              <a href="https://libguides.mit.edu/c.php?g=1353444&p=9994954" target="_blank" rel="noreferrer">
                <img src={mitlogo} alt="featuredImage4" style={{ width: "80px", height: "auto", margin: "10px" }} />
              </a>
              <a>
                <img
                  src={marketwatchlogo}
                  alt="featuredImage1"
                  style={{ width: "200px", height: "auto", margin: "10px" }}
                />
              </a>
              <a
                href="https://www.benzinga.com/pressreleases/23/05/ab32530429/a-i-archives-is-making-it-possible-for-students-researchers-to-accurately-cite-chatgpt-and-bard"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={bezingalogo}
                  alt="featuredImage2"
                  style={{ width: "180px", height: "auto", margin: "10px" }}
                />
              </a>
            </div>

            <Divider
              style={{
                fontSize: "20px",
                marginBottom: "35px",
                backgroundColor: "#808080",
              }}
            ></Divider>

            <ButtonGroupComponent
              setActiveButton={setActiveButton}
              setCheckboxState={setCheckboxState}
              activeButton={activeButton}
              checkboxState={checkboxState}
            />
          </div>
          <div style={{ padding: "10px", margin: "auto" }} className="col-11 col-lg-8">
            {noFiltersActive ? (
              <p style={{ textAlign: "center" }}>
                <b>No Model Filters Selected</b>
              </p>
            ) : isTopPostFirstLoading ? (
              <LoadingAnimation />
            ) : (
              <InfiniteScroll
                dataLength={filteredPosts.length} //This is important field to render the next data
                next={() => {
                  if (!noFiltersActive) {
                    if (activeButton === 1) {
                      getRecentPosts();
                    } else if (activeButton === 3) {
                      getRandomPosts();
                    } else {
                      getTopPosts();
                    }
                  }
                }}
                hasMore={endInfiniteScrl && filteredPosts.length < 100}
                loader={noFiltersActive ? null : <LoadingAnimation />}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen the limit for posts in this category!</b>
                  </p>
                }
                style={{ overflow: "visible" }}
              >
                <div className="steps row">
                  {filteredPosts.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        title={item.title}
                        id={item.id}
                        view={false}
                        tags={item.tags}
                        photoURL={item.photoURL}
                        views={item.views}
                        created={item.created}
                        relatedPosts={item.relatedPosts}
                        suggestedPosts={item.suggestedPosts}
                        liked={item.liked}
                        bookmarked={item.bookmarked}
                        shared={item.shared}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Home);
