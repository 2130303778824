import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill-new';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import 'react-quill-new/dist/quill.snow.css';

const ReplyBox = ({ parentId, onReply }) => {
  const [replyContent, setReplyContent] = useState('');
  const [isReplying, setIsReplying] = useState(false);
  const [reportStatus, setReportStatus] = useState({});
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    onAuthStateChanged(firebase_auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setProfile({ ...user, token });
        } catch (error) {
          console.error('Error retrieving ID token:', error);
        }
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  const handleReply = () => {
    if (replyContent.trim() !== '') {
      onReply(replyContent);
      setReplyContent('');
      setIsReplying(false);
    }
  };

  const handleShare = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    const commentLink = `${baseUrl}#${parentId}`;
    navigator.clipboard
      .writeText(commentLink)
      .then(() => {
        alert(`Comment link copied to clipboard: ${commentLink}`);
      })
      .catch((err) => {
        console.error('Failed to copy!', err);
        alert('Failed to copy the link. Please try manually.');
      });
  };

  const handleReport = () => {
    setReportStatus({ ...reportStatus, [parentId]: true });
    alert(`Comment ID: #${parentId} reported!`);
  };

  return (
    <div className='mt-2'>
      {!isReplying ? (
        <div className='flex flex-wrap items-center gap-x-3 gap-y-2'>
          {profile.uid && (
            <button
              onClick={() => setIsReplying(true)}
              className='inline-flex items-center justify-center whitespace-nowrap font-medium transition-all hover:bg-red-900 rounded-md h-auto gap-1 px-2 py-1 text-md text-white border-0 bg-[#6B1E1E]'
            >
              <span className='relative cursor-pointer'>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='size-3.5 bg-[#6B1E1E] fill-transparent mr-1'
                >
                  <path d='M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z'></path>
                  <path d='M13 8H7'></path>
                  <path d='M17 12H7'></path>
                </svg>
              </span>
              Reply
            </button>
          )}
          <button
            onClick={handleShare}
            className='inline-flex items-center justify-center whitespace-nowrap font-medium transition-all hover:bg-red-900 rounded-md h-auto gap-1 px-2 py-1 text-md text-white border-0 bg-[#6B1E1E]'
          >
            <span className='relative cursor-pointer'>
              <svg
                width='16'
                height='16'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='size-3 bg-[#6B1E1E]'
              >
                <circle cx='18' cy='5' r='3'></circle>
                <circle cx='6' cy='12' r='3'></circle>
                <circle cx='18' cy='19' r='3'></circle>
                <line x1='8.59' x2='15.42' y1='13.51' y2='17.49'></line>
                <line x1='15.41' x2='8.59' y1='6.51' y2='10.49'></line>
              </svg>
            </span>
            Share
          </button>
          {/* <button
            onClick={handleReport}
            className='inline-flex items-end justify-end ml-auto whitespace-nowrap font-medium transition-all rounded h-auto gap-1 px-1 py-0.5 text-md text-gray-500 border-0'
          >
            <span className='relative cursor-pointer'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='h-3 w-3 stroke-current'
              >
                <path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
                <line x1='4' x2='4' y1='22' y2='15'></line>
              </svg>
            </span>
          </button> */}
        </div>
      ) : (
        <div className='flex flex-col mt-2'>
          <ReactQuill
            theme='snow'
            value={replyContent}
            onChange={(value) => setReplyContent(value)}
            className='w-full border rounded-md p-2 text-md'
            placeholder='Write a reply...'
            rows='2'
          />
          <div className='mt-2 flex gap-2'>
            <button
              onClick={handleReply}
              className='px-3 py-1 bg-[#6B1E1E] text-white text-md rounded-md hover:bg-red-900 rounded-md border-0'
            >
              POST
            </button>
            <button
              onClick={() => setIsReplying(false)}
              className='px-3 py-1 bg-gray-200 text-gray-600 text-md rounded-md border-0'
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReplyBox;
