import React, { useState } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const AddComment = ({ onAdd }) => {
  const [newComment, setNewComment] = useState('');

  const handleSubmit = () => {
    if (newComment.trim() !== '') {
      onAdd(newComment);
      setNewComment('');
    }
  };

  return (
    <div className='mt-4'>
      <ReactQuill
        theme='snow'
        value={newComment}
        onChange={(value) => setNewComment(value)}
        className='w-full border rounded-md'
        placeholder='Add a comment...'
      />
      <button onClick={handleSubmit} className='mt-2 px-4 py-2 bg-[#6B1E1E] border-0 text-white rounded-md'>
        Comment
      </button>
    </div>
  );
};

export default AddComment;
