import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../App.scss';
import styles from '../../assets/utils.module.css';
import { db, storage } from '../../firestore.js';
import { ref, getDownloadURL } from 'firebase/storage';
import cn from 'classnames';
import '../../assets/globals.scss';
import '../../assets/highlight.css';
import HomeHeader from '../../components/Headers/HomeHeader';
import StarRating from '../../components/StarRating/StarRating';
import SaveFolder from '../../components/SaveFolder/SaveFolder';
import FileUpload from '../../components/FileUpload/FileUpload';
import BottomButtons from '../../components/BottomButtons/BottomButtons';
import ConversationTag from '../../components/ConversationTag/ConversationTag';
import Card from '../../components/Cards/Card';
import { doc, setDoc, updateDoc, arrayUnion, getDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import GPTAvatar from './GPTAvatar';
import { Divider } from '@mui/material';
import ConversationTitle from '../../components/ConversationTitle/ConversationTitle';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import DeleteConvo from '../../components/DeleteConvo/DeleteConvo';
import CommentSection from '../../components/CommentSection/CommentSection';

const Conversation = () => {
  const [profile, setProfile] = useState(false);
  const [convoUserId, setConvoUserId] = useState(null);
  const [postId, setPostId] = useState(null);
  const [postRefId, setPostRefId] = useState(null);
  const [pageTitle, setPageTitle] = useState('');
  const [collapsed, setCollapsed] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    // if opened, send to telemetry
    if (!collapsed) telemetry();
  }, [collapsed]);

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
    if (postRefId) {
      let postRef = doc(db, postRefId, id);
      getDoc(postRef).then((docSnap) => {
        if (docSnap.exists()) {
          setConvoUserId(docSnap.data().userid);
          setPostId(docSnap.data().postId);
        }
      });
    }
  }, [onAuthStateChanged, firebase_auth, postRefId]);

  const handleFeedback = (isPositive) => {
    if (!profile) {
      window.alert('You need to Login on the top right and have shared a post to save!');
    } else {
      setFeedbackType(isPositive ? 'positive' : 'negative');
      setLike(isPositive);
      setDislike(!isPositive);
      setShowPopover(true);
    }
  };

  const handleClosePopover = () => {
    setShowPopover(false);
    setComment('');
  };

  const handleSendFeedback = () => {
    // Here you would typically send the feedback to your server
    console.log(`Feedback: ${feedbackType}, Comment: ${comment}`);
    handleClosePopover();
  };

  const [humanPicture, setHumanPicture] = useState('');
  const [items, setItems] = useState([]);
  const [content, setContent] = useState(null);
  const [related, setRelated] = useState([]);
  const [model, setModel] = useState('ChatGPT-3');
  const [isPrivate, setIsPrivate] = useState(false);
  const [relatedId, setRelatedId] = useState('');
  const [success, setSuccess] = useState(false);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [passAlert, setPassAlert] = useState('');
  const [show, setShowResult] = useState(false);
  const [comment, setComment] = useState('');
  const [feedbackType, setFeedbackType] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);
  const handleChange = (event) => setRelatedId(event.target.value);
  const handleSuccessClose = () => setSuccess(false);
  const handleSuccessOpen = () => {
    setSuccess(true);
    getPostConversation(postRefId);
    setTimeout(() => handleSuccessClose(), 2500);
  };

  const handleSubmit = () => {
    if (relatedId != id && profile?.uid) {
      let userRef = doc(db, 'users', profile.uid);
      let relatedRef = doc(db, postRefId, relatedId);
      let postRef = doc(db, postRefId, id);
      getDoc(relatedRef).then((docSnap) => {
        if (docSnap.exists()) {
          updateDoc(postRef, {
            relatedPosts: arrayUnion(relatedId),
          });
          updateDoc(userRef, {
            [`relatedPostMap.${relatedId}`]: arrayUnion(id),
          });
          setRelatedId('');
          handleSuccessOpen();
        }
      });
    }
  };

  const telemetry = async () => {
    if (profile?.uid) {
      const telemetryRef = doc(db, 'util', 'commentNavClicks');
      try {
        // Fetch the current telemetry data
        const telemetryDoc = await getDoc(telemetryRef);
        const userClicks = telemetryDoc.data()?.[profile.uid] || null;

        if (userClicks) {
          // Update existing telemetry data
          await updateDoc(telemetryRef, {
            [`${profile.uid}.clickCount`]: userClicks.clickCount + 1,
            [`${profile.uid}.lastClick`]: serverTimestamp(),
          });
        } else {
          // Set initial telemetry data for this user
          await updateDoc(telemetryRef, {
            [profile.uid]: {
              clickCount: 1,
              firstClick: serverTimestamp(),
              lastClick: serverTimestamp(),
            },
          });
        }
      } catch (error) {
        console.error('Error updating telemetry:', error);

        // Create the document if it doesn't exist yet
        if (error.code === 'not-found') {
          await setDoc(telemetryRef, {
            [profile.uid]: {
              clickCount: 1,
              firstClick: serverTimestamp(),
              lastClick: serverTimestamp(),
            },
          });
        }
      }
    }
  };

  const RedLockIcon = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='#6B1E1E'
      height='20px'
      width='20px'
      version='1.1'
      id='Layer_1'
      viewBox='0 0 330 330'
    >
      <g id='XMLID_509_'>
        <path
          id='XMLID_510_'
          d='M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85   S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15   s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25   C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z'
        />
      </g>
    </svg>
  );

  const popoverStyle = {
    position: 'absolute',
    bottom: '100px',
    left: '0',
    zIndex: 1000,
    backgroundColor: '#f0f0ef',
    color: '#2d2d2d',
    padding: '15px',
    borderRadius: '5px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
    width: '300px',
  };

  useEffect(() => {
    if (id.slice(-2) === '-p') {
      setPostRefId('privatePosts');
      getPostConversation('privatePosts');
    } else {
      setPostRefId('posts');
      getPostConversation('posts');
    }
    incrementView();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log(showPopover, popoverRef, popoverRef.current);
      if (showPopover && popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  const incrementView = () => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/incrementView/${id}`, {
      method: 'POST',
    });
  };

  const getPostConversation = (postRefId) => {
    const docRef = doc(db, postRefId, id);
    const convo = [];
    let related = [];
    getDoc(docRef).then(async (docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().version) {
          if (docSnap.data().conversation_url) {
            try {
              const fileRef = ref(storage, `conversations/${docSnap.data().userid}/${docSnap.data().postId}`);
              const url = await getDownloadURL(fileRef);
              const response = await fetch(url);
              const content = await response.text();
              setContent(content);
            } catch (error) {
              console.error('Error fetching file content:', error);
            }
          } else {
            setContent(docSnap.data().content);
          }
        } else {
          const userRef = doc(db, 'users', docSnap.data()?.userid);
          getDoc(userRef).then((docSnap) => {
            if (docSnap.exists()) {
              setHumanPicture(docSnap.data().photoURL);
            } else {
              setHumanPicture('');
            }
          });
          docSnap.data().conversation.forEach((conversation) => {
            convo.push({
              value: conversation.value.replace(/\\/g, ''),
              from: conversation.from,
            });
          });
          setItems(convo);
        }
        setIsPrivate(docSnap.data()?.private);
        setModel(docSnap.data().tags[0]);
        setRelated([]);
        related = Array.from(new Set([...docSnap.data()?.relatedPosts, ...docSnap.data()?.suggestedPosts]));

        setPageTitle(docSnap.data().title);

        if (related.length) {
          related.forEach((relatedId) => {
            const docRef = doc(db, postRefId, relatedId);
            getDoc(docRef).then((docSnap) => {
              if (docSnap.exists()) {
                const userRef = doc(db, 'users', docSnap.data().userid);
                getDoc(userRef).then((userDoc) => {
                  if (userDoc.exists()) {
                    setRelated((relatedConvo) => [
                      ...relatedConvo,
                      {
                        title: docSnap.data().title,
                        id: docSnap.data().postId,
                        tags: docSnap.data().tags,
                        photoURL: userDoc.data().photoURL,
                        views: docSnap.data().views,
                        created: docSnap.data().created,
                        relatedPosts: docSnap.data().relatedPosts,
                        suggestedPosts: docSnap.data().suggestedPosts,
                      },
                    ]);
                  }
                });
              }
            });
          });
        }
      } else {
        setContent(null);
        setItems([]);
        setRelated([]);
      }
    });
  };

  return (
    <>
      <div className='Home'>
        <Helmet>
          <title>{pageTitle}</title>
          {/* You can add other meta tags here as needed */}
        </Helmet>
        <HomeHeader />
        <BottomButtons />
        <div
          className={`container transition-all ${!collapsed && 'mr-[420px]'}`}
          style={{
            transition: 'margin 0.3s ease',
          }}
        >
          <div className='content row'>
            <div
              className={`col-12 col-lg-8 transition-all ${collapsed ? 'ml-auto mr-auto' : 'ml-0'}`}
              style={{
                transition: 'margin 0.3s ease',
                position: 'relative',
              }}
            >
              <ConversationTitle profile={profile.uid} userId={convoUserId} postId={postId} />
              <ConversationTag profile={profile.uid} userId={convoUserId} postId={postId} inputBarWidth='50%' />
            </div>
            <div
              className={`flex flex-row col-12 col-lg-8 justify-between bg-transparent transition-all ${
                collapsed ? 'm-auto' : 'ml-[20px]'
              }`}
              style={{
                transition: 'margin 0.3s ease',
                position: 'relative',
              }}
            >
              <div className='flex-1 mr-2' style={{ padding: '1rem 1rem 1rem 0' }}>
                <FileUpload uid={profile.uid} convoUserId={convoUserId} postId={postId} />
              </div>
              <div
                className='flex-1 ml-2'
                style={{ maxWidth: '200px', marginTop: 'auto', padding: '1rem 0rem 1rem 1rem' }}
              >
                {profile.uid === convoUserId && (
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        fontSize: '14px',
                        height: '37px',
                        marginLeft: 'auto',
                        marginTop: 'auto',
                        marginRight: '0px',
                        marginBottom: '0px',
                        width: 'fit-content',
                        borderColor: 'unset',
                      }}
                      className='flex items-center px-4 py-2 bg-red-900 text-white rounded-lg cursor-pointer hover:bg-red-800'
                      id='dropdown-basic'
                    >
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className='hover:bg-[#6b1e1e] hover:text-white'>
                        <SaveFolder
                          uid={profile.uid}
                          convoUserId={convoUserId}
                          postId={postId}
                          setPassAlert={setPassAlert}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item className='hover:bg-[#6b1e1e] hover:text-white'>
                        <DeleteConvo
                          uid={profile.uid}
                          convoUserId={convoUserId}
                          postId={postId}
                          setPassAlert={setPassAlert}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <Snackbar
                  open={passAlert !== ''}
                  autoHideDuration={2000}
                  onClose={() => setPassAlert('')}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Alert
                    onClose={() => setPassAlert('')}
                    severity={passAlert.includes('Error') ? 'error' : 'success'}
                    variant='filled'
                    elevation={6}
                    sx={{ width: '100%' }}
                  >
                    {passAlert}
                  </Alert>
                </Snackbar>
              </div>
            </div>
            <div
              className={`submit-box col-12 col-lg-8 transition-all ${collapsed ? 'ml-auto' : 'ml-5'}`}
              style={{
                transition: 'margin 0.3s ease',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <div className='row hide-on-print'>
                <div className='col-7'>
                  <h1 style={{ fontSize: '18px' }}>Questions by Humans, Answers by A.I.</h1>
                  {typeof postId === 'string' && !postId.endsWith('-p') && (
                    <StarRating postId={postId} uid={profile.uid} />
                  )}
                </div>
                <div className='col-5'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <h1
                      style={{
                        fontSize: '14px',
                        textAlign: 'end',
                        whiteSpace: 'nowrap',
                        maxWidth: '500px',
                        marginRight: '5px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {pageTitle}
                    </h1>
                    {isPrivate && <RedLockIcon />}
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-center bg-transparent conversation-box'>
                {content ? (
                  <div style={{ width: '100%', padding: '20px' }} dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                  items?.map((item, idx) => (
                    <div
                      key={idx.toString()}
                      className={cn(
                        'relative bg-transparent text-gray-700 w-full border-b dark:border-gray-700 border-gray-200',
                        {
                          'bg-gray-100 bg-transparent': item.from === 'gpt',
                        }
                      )}
                    >
                      <div className='relative mx-auto max-w-screen-xl dark:text-gray-500 text-gray-700 w-full px-4 py-2'>
                        <div className='w-full max-w-screen-md flex flex-1 gap-4 md:gap-6 leading-[1]'>
                          {item.from === 'human' ? (
                            <img
                              style={{ marginLeft: '23px' }}
                              className='rounded-sm h-[41px]'
                              alt='Avatar of the person chatting'
                              width='41'
                              height='41'
                              src={humanPicture}
                            />
                          ) : (
                            !item.value?.includes('M37.5324 16.8707C37.9808') && <GPTAvatar model={model} />
                          )}
                          <div className='flex flex-col'>
                            {item.from === 'human' ? (
                              <p className='pb-2' style={{ whiteSpace: 'pre-wrap' }}>
                                {item.value}
                              </p>
                            ) : (
                              <div className={styles.response} dangerouslySetInnerHTML={{ __html: item.value }} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {showPopover && (
                  <div ref={popoverRef} style={popoverStyle}>
                    <h5>Why did you choose this rating? (optional)</h5>
                    <textarea
                      className='form-control mb-2'
                      rows='3'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder='Provide additional feedback'
                    />
                    <div>
                      <button
                        style={{
                          color: '#6b1e1e',
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                          margin: '0px',
                          fontSize: '15px',
                          width: '50px',
                          padding: '0px',
                        }}
                        className='btn btn-primary'
                        onClick={handleSendFeedback}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
                {/* <div style={{ display: 'flex', maxWidth: '150px', marginRight: 'auto', marginLeft: '100px' }}>
                  <button style={{ color: '#6b1e1e' }} className='upload btn' onClick={() => handleFeedback(true)}>
                    <i
                      style={{ fontSize: '25px' }}
                      className={`fa ${like ? 'fa-thumbs-up' : 'fa-thumbs-o-up'}`}
                      aria-hidden='true'
                    ></i>
                  </button>
                  <button style={{ color: '#6b1e1e' }} className='upload btn' onClick={() => handleFeedback(false)}>
                    <i
                      style={{ padding: '5px', fontSize: '25px' }}
                      className={`fa ${dislike ? 'fa-thumbs-down' : 'fa-thumbs-o-down'}`}
                      aria-hidden='true'
                    ></i>
                  </button>
                  <button style={{ color: '#6b1e1e' }} className='upload btn' onClick={() => setShowResult(true)}>
                    <i style={{ fontSize: '25px' }} class='fa fa-eye' aria-hidden='true'></i>
                  </button>
                </div> */}
              </div>
              <div style={{ height: '20px' }} />
            </div>
          </div>
          <CommentSection
            postId={id}
            convoUserId={convoUserId}
            setPassAlert={setPassAlert}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Modal style={{ width: '100vw' }} show={show} onHide={() => setShowResult(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title className='font-weight-bold'>Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ whiteSpace: 'pre-wrap', paddingTop: '0px' }}>
              <div style={{ display: 'flex', maxWidth: '100px', marginRight: 'auto', marginLeft: '0px' }}>
                <button style={{ display: 'flex', color: '#6b1e1e', padding: '0px' }} className='upload btn'>
                  <p style={{ padding: '5px' }}>20</p>
                  <i style={{ padding: '5px', fontSize: '25px' }} className='fa fa-thumbs-o-up' aria-hidden='true'></i>
                </button>
                <button style={{ display: 'flex', color: '#6b1e1e', padding: '0px' }} className='upload btn'>
                  <p style={{ paddingTop: '2px', paddingLeft: '15px' }}>5</p>
                  <i
                    style={{ padding: '5px', fontSize: '25px' }}
                    className='fa fa-thumbs-o-down'
                    aria-hidden='true'
                  ></i>
                </button>
              </div>
              <div style={{ padding: '10px' }} className='row'>
                <h1
                  className='col-12'
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    overflow: 'hidden', // Add this
                  }}
                >
                  <img
                    src='https://lh3.googleusercontent.com/a/AEdFTp5rg5MOwPZ_iM6EFTd8zCDMfjCAz1B_yE30y0D3XQ=s96-c'
                    alt='Your description'
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '10px',
                      borderRadius: '80%',
                    }}
                  />
                  <div
                    style={{
                      minWidth: 0,
                      overflowWrap: 'anywhere',
                    }}
                  >
                    hi this is my feedback
                  </div>
                </h1>
              </div>
              <div style={{ padding: '10px' }} className='row'>
                <h1
                  className='col-12'
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    display: 'flex',
                    overflow: 'hidden', // Add this
                  }}
                >
                  <img
                    src='https://lh3.googleusercontent.com/a/AEdFTp5rg5MOwPZ_iM6EFTd8zCDMfjCAz1B_yE30y0D3XQ=s96-c'
                    alt='Your description'
                    style={{
                      width: '26px',
                      height: '26px',
                      marginRight: '10px',
                      borderRadius: '80%',
                    }}
                  />
                  <div
                    style={{
                      minWidth: 0,
                      overflowWrap: 'anywhere',
                    }}
                  >
                    Anooother one ----
                    AnoootherAnoootherAnoootherAnoootherAnoootherAnoootherAnoootherAnoootherAnoootherAnooother
                  </div>
                </h1>
              </div>
            </Modal.Body>
            <div style={{ height: '25px' }} />
          </Modal>
          {related.length > 0 ? (
            <>
              <div style={{ height: '20px' }} />
              <Divider
                style={{
                  fontSize: '18px',
                  marginTop: '50px',
                  width: '65%',
                  margin: 'auto',
                }}
              >
                Related
              </Divider>
              <div style={{ padding: '10px', margin: 'auto', cursor: 'pointer' }} className='col-12 col-lg-8'>
                <div className='steps row'>
                  {related.map((item, index) => {
                    return (
                      <Card
                        key={index}
                        title={item.title}
                        id={item.id}
                        view={false}
                        tags={item.tags}
                        photoURL={item.photoURL}
                        views={item.views}
                        created={item.created}
                        relatedPosts={item.relatedPosts}
                        suggestedPosts={item.suggestedPosts}
                      />
                    );
                  })}
                </div>
              </div>
              <div style={{ height: '150px' }} />
            </>
          ) : (
            <div style={{ height: '150px' }} />
          )}
        </div>
      </div>
      <div className='print-submit-box submit-box col-12 col-lg-8'>
        <div className='row hide-on-print'>
          <div className='col-7'>
            <h1 style={{ fontSize: '18px' }}>Questions by Humans, Answers by A.I.</h1>
          </div>
          <div className='col-5'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <h1
                style={{
                  fontSize: '14px',
                  textAlign: 'end',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  marginRight: '5px',
                }}
              >
                ID: {id}
              </h1>
              {isPrivate && <RedLockIcon />}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center bg-transparent conversation-box'>
          {content ? (
            <div style={{ width: '100%', padding: '20px' }} dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            items?.map((item, idx) => (
              <div
                key={idx.toString()}
                className={cn(
                  'relative bg-transparent text-gray-700 w-full border-b dark:border-gray-700 border-gray-200',
                  {
                    'bg-gray-100 bg-transparent': item.from === 'gpt',
                  }
                )}
              >
                <div className='relative mx-auto max-w-screen-xl dark:text-gray-500 text-gray-700 w-full px-4 py-2'>
                  <div className='w-full max-w-screen-md flex flex-1 gap-4 md:gap-6 leading-[1.75]'>
                    {item.from === 'human' ? (
                      <img
                        style={{ marginLeft: '23px' }}
                        className='rounded-sm h-[41px]'
                        alt='Avatar of the person chatting'
                        width='41'
                        height='41'
                        src={humanPicture}
                      />
                    ) : (
                      !item.value?.includes('M37.5324 16.8707C37.9808') && (
                        <GPTAvatar
                          model={model}
                          innerColor={model?.includes('ChatGPT-4') ? 'rgb(17 24 39)' : 'rgb(16 163 127)'}
                        />
                      )
                    )}
                    <div className='flex flex-col'>
                      {item.from === 'human' ? (
                        <p className='pb-2' style={{ whiteSpace: 'pre-wrap' }}>
                          {item.value}
                        </p>
                      ) : (
                        <div className={styles.response} dangerouslySetInnerHTML={{ __html: item.value }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default connect()(Conversation);
