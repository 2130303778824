import React, { useEffect, useState } from 'react';
import ReplyBox from './ReplyBox';
import ReactQuill from 'react-quill-new';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import 'react-quill-new/dist/quill.snow.css';

function formatAuthorName(author) {
  if (!author) return ''; // Handle null/undefined

  const nameParts = author.split(' ');

  // If there's only one name, return it directly
  if (nameParts.length === 1) {
    return nameParts[0];
  }

  // Handle first and last name formatting
  const firstName = nameParts[0];
  const lastNameInitial = nameParts[nameParts.length - 1].charAt(0);

  return `${firstName} ${lastNameInitial}.`;
}

const CommentList = ({ postId, convoUserId, setPassAlert, comments, setComments }) => {
  const [expandedReplies, setExpandedReplies] = useState({});
  const [profile, setProfile] = useState(false);
  const [votes, setVotes] = useState(new Map());

  useEffect(() => {
    onAuthStateChanged(firebase_auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          setProfile({ ...user, token });
        } catch (error) {
          console.error('Error retrieving ID token:', error);
        }
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  const handleUpvote = (id) => {
    const currentVoteStatus = votes.get(`${id}`) || { upvoted: false, downvoted: false };
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? { ...comment, likes: currentVoteStatus.upvoted ? comment.likes - 1 : comment.likes + 1 }
          : comment
      )
    );
    // Create a new Map instance to update the state
    setVotes((prevVotes) => {
      const newVotes = new Map(prevVotes);
      newVotes.set(`${id}`, {
        upvoted: !currentVoteStatus.upvoted,
        downvoted: false,
      });
      return newVotes;
    });
    setVoteCommentAsync(id, 1);
  };

  const handleDownvote = (id) => {
    const currentVoteStatus = votes.get(`${id}`) || { upvoted: false, downvoted: false };
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              likes: currentVoteStatus.downvoted ? comment.likes + 1 : comment.likes - 1,
            }
          : comment
      )
    );
    // Create a new Map instance to update the state
    setVotes((prevVotes) => {
      const newVotes = new Map(prevVotes);
      newVotes.set(`${id}`, {
        upvoted: false,
        downvoted: !currentVoteStatus.downvoted,
      });
      return newVotes;
    });
    setVoteCommentAsync(id, -1);
  };

  const handleEdit = (comment) => {
    const id = comment.id;
    setComments((prev) =>
      prev.map((comment) => (comment.id === id ? { ...comment, editing: !comment.editing } : comment))
    );
    if (comment.editing) setEditCommentAsync(id, comment.content);
  };

  const setNewComment = (id, value) => {
    setComments((prev) => prev.map((comment) => (comment.id === id ? { ...comment, content: value } : comment)));
  };

  const handleDelete = (id) => {
    setComments((prev) => prev.filter((comment) => comment.id !== id));
    setDeleteCommentAsync(id);
  };

  const handleAddReply = (parentId, replyContent) => {
    const id = Date.now();
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === parentId
          ? {
              ...comment,
              replies: [
                ...comment.replies,
                {
                  id: id,
                  content: replyContent,
                  author: profile?.displayName,
                  likes: 0,
                  userId: profile?.uid,
                  op_id: convoUserId,
                  time: new Date().toISOString(),
                },
              ],
            }
          : comment
      )
    );
    setReplyToCommentAsync(parentId, replyContent, id);
  };

  const handleReplyUpvote = (id, replyId) => {
    const currentVoteStatus = votes.get(`${id}-${replyId}`) || { upvoted: false, downvoted: false };
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === replyId
                  ? {
                      ...reply,
                      likes: currentVoteStatus.upvoted ? reply.likes - 1 : reply.likes + 1,
                    }
                  : reply
              ),
            }
          : comment
      )
    );

    // Create a new Map instance to update the state
    setVotes((prevVotes) => {
      const newVotes = new Map(prevVotes);
      newVotes.set(`${id}-${replyId}`, {
        upvoted: !currentVoteStatus.upvoted,
        downvoted: false,
      });
      return newVotes;
    });
    setVoteReplyAsync(id, replyId, 1);
  };

  const handleReplyDownvote = (id, replyId) => {
    const currentVoteStatus = votes.get(`${id}-${replyId}`) || { upvoted: false, downvoted: false };
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === replyId
                  ? {
                      ...reply,
                      likes: currentVoteStatus.downvoted ? reply.likes + 1 : reply.likes - 1,
                    }
                  : reply
              ),
            }
          : comment
      )
    );
    // Create a new Map instance to update the state
    setVotes((prevVotes) => {
      const newVotes = new Map(prevVotes);
      newVotes.set(`${id}-${replyId}`, {
        upvoted: false,
        downvoted: !currentVoteStatus.downvoted,
      });
      return newVotes;
    });
    setVoteReplyAsync(id, replyId, -1);
  };
  const handleReplyEdit = (id, reply) => {
    const replyId = reply.id;
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply.id === replyId ? { ...reply, editing: !reply.editing } : reply
              ),
            }
          : comment
      )
    );
    if (reply.editing) setEditReplyAsync(id, reply);
  };

  const setNewReplyComment = (id, replyId, value) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              replies: comment.replies.map((reply) => (reply.id === replyId ? { ...reply, content: value } : reply)),
            }
          : comment
      )
    );
  };

  const handleReplyDelete = (id, replyId) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              replies: comment.replies.filter((reply) => reply.id !== replyId),
            }
          : comment
      )
    );
    setDeleteReplyAsync(id, replyId);
  };

  const setVoteCommentAsync = (id, vote) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/voteComment/${postId}/${id}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ vote: vote }),
    })
      .then(async (res) => {
        setPassAlert('Upvote added successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to Upvote. Please try again.');
      });
  };

  const setVoteReplyAsync = (id, replyId, vote) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/voteReply/${postId}/${id}/${replyId}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ vote: vote }),
    })
      .then(async (res) => {
        setPassAlert('Upvote added successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to Upvote. Please try again.');
      });
  };

  const setEditReplyAsync = (id, reply) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/editReply/${postId}/${id}/${reply.id}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ text: reply.content }),
    })
      .then(async (res) => {
        setPassAlert('Reply Edited successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to Edit reply. Please try again.');
      });
  };

  const setDeleteReplyAsync = (id, replyId) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/deleteReply/${postId}/${id}/${replyId}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
    })
      .then(async (res) => {
        setPassAlert('Reply deleted successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to delete reply. Please try again.');
      });
  };

  const setReplyToCommentAsync = (id, text, tempId) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/addReplyToComment/${postId}/${id}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ text: text }),
    })
      .then(async (res) => {
        const data = await res.json();
        const newId = data.replyId;
        // Update the comment with the new ID in replies
        setComments((prev) =>
          prev.map((comment) =>
            comment.id === id
              ? {
                  ...comment,
                  replies: comment.replies.map((reply) => (reply.id === tempId ? { ...reply, id: newId } : reply)),
                }
              : comment
          )
        );
        setPassAlert('Reply added successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to add reply. Please try again.');
      });
  };

  const setEditCommentAsync = (id, text) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/editComment/${postId}/${id}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
      body: JSON.stringify({ text: text }),
    })
      .then(async (res) => {
        setPassAlert('Comment edited successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to edit comment. Please try again.');
      });
  };

  const setDeleteCommentAsync = (id) => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/deleteComment/${postId}/${id}/${profile?.uid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${profile?.token}`,
      },
    })
      .then(async (res) => {
        setPassAlert('Comment deleted successfully!');
      })
      .catch((error) => {
        setPassAlert('Error: Failed to delete comment. Please try again.');
      });
  };

  const toggleReplies = (id) => {
    setExpandedReplies((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderReplies = (replies, parentId) => {
    const isExpanded = expandedReplies[parentId] || false;

    const visibleReplies = isExpanded ? replies : replies.slice(0, 1);
    const isCollapsed = replies.length > 1 && !isExpanded;

    return (
      <div className='mt-2 pl-4'>
        {visibleReplies.map((reply) => (
          <div key={reply.id} className='flex relative'>
            <div className='flex flex-[0] flex-col items-center'>
              <div className='flex-grow border-dashed border-red bg-red-800 border-[0.8px]'></div>
              <div className='self-baseline rounded-full bg-red-800 size-[5px]'></div>
            </div>
            <div className='w-full mt-2 pl-2 pb-3 rounded-md'>
              <div className='flex w-full gap-1'>
                <div className='flex w-full flex-wrap items-center gap-1'>
                  <span className='font-medium'>{formatAuthorName(reply.author)}</span>
                  {reply.op_id === reply.userId && <span className='text-gray-500'>(author)</span>}
                  <div className='flex size-3 items-center justify-center'>
                    <div className='size-1 rounded-full bg-red-800'></div>
                  </div>
                  <span className='text-xs text-gray-500'>{new Date(reply.time).toLocaleString()}</span>
                </div>
                <div className='flex items-center justify-end gap-2 font-normal'>
                  <div className='flex items-center gap-2'>
                    {(profile?.uid === reply.userId || profile?.uid === reply.op_id) && (
                      <>
                        <button
                          onClick={() => handleReplyEdit(parentId, reply)}
                          className='bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleReplyDelete(parentId, reply.id)}
                          className='bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
                        >
                          Delete
                        </button>
                      </>
                    )}
                    {profile?.uid && (
                      <div className='flex items-center justify-between gap-2 rounded-3xl bg-gray-100 px-1.5 py-1'>
                        <button
                          onClick={() => handleReplyUpvote(parentId, reply.id)}
                          className='flex  h-6 text-md rounded-md items-center justify-between gap-0.5 pr-1 bg-[#6B1E1E] border-0 text-white'
                        >
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={`'fill-red-800' h-4 w-4`}
                          >
                            <path d='M9 18v-6H5l7-7 7 7h-4v6H9z'></path>
                          </svg>
                          {reply.likes !== 0 && <span className='text-sm'>{reply.likes}</span>}
                        </button>
                        <div className='h-6 w-[1px] text-md rounded-md bg-gray-400'></div>
                        <button
                          onClick={() => handleReplyDownvote(parentId, reply.id)}
                          className='flex h-6 text-md rounded-md border-0 items-center justify-between gap-0.5 text-gray-500'
                        >
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={`'fill-gray-500' h-4 w-4`}
                          >
                            <path d='M15 6v6h4l-7 7-7-7h4V6h6z'></path>
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {reply.editing ? (
                <>
                  <ReactQuill
                    theme='snow'
                    value={reply.content}
                    onChange={(value) => setNewReplyComment(parentId, reply.id, value)}
                    className='w-full border rounded-md'
                    placeholder='Add a comment...'
                  />
                  <button
                    onClick={() => handleReplyEdit(parentId, reply)}
                    className='mt-2 px-4 py-2 bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
                  >
                    POST
                  </button>
                </>
              ) : (
                <div
                  className='prose max-w-none bg-gray-100 p-4 rounded-md mt-2'
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                />
              )}
            </div>
          </div>
        ))}
        {isCollapsed && (
          <div className='flex justify-center'>
            <button onClick={() => toggleReplies(parentId)} className='mt-2 text-gray-500 round-md text-md border-0'>
              Click to view all ({replies.length})
            </button>
          </div>
        )}
        {isExpanded && replies.length > 1 && (
          <div className='flex justify-center'>
            <button onClick={() => toggleReplies(parentId)} className='mt-2 text-gray-500 round-md text-md border-0'>
              Collapse Replies
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='mt-4'>
      {comments.map((comment) => (
        <div key={comment.id} className='border rounded-md p-3 mb-3 bg-gray-50'>
          <div className='flex w-full gap-1'>
            <div className='flex w-full flex-wrap items-center gap-1'>
              <span className='font-medium'>{formatAuthorName(comment.author)}</span>
              {comment.op_id === comment.userId && <span className='text-gray-500'>(author)</span>}
              <div className='flex size-3 items-center justify-center'>
                <div className='size-1 rounded-full bg-red-800'></div>
              </div>
              <span className='text-xs text-gray-500'>{new Date(comment.time).toLocaleString()}</span>
            </div>
            <div className='flex items-center justify-end gap-2 font-normal'>
              <div className='flex items-center gap-2 text-md'>
                {(profile?.uid === comment.userId || profile?.uid === comment.op_id) && (
                  <>
                    <button
                      onClick={() => handleEdit(comment)}
                      className='bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(comment.id)}
                      className='bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
                    >
                      Delete
                    </button>
                  </>
                )}
                {profile?.uid && (
                  <div className='flex items-center justify-between gap-2 rounded-3xl bg-gray-100 px-1.5 py-1'>
                    <button
                      onClick={() => handleUpvote(comment.id)}
                      className='flex h-6 text-md rounded-md items-center justify-between gap-0.5 pr-1 bg-[#6B1E1E] border-0 text-white'
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={`'fill-red-800' h-4 w-4`}
                      >
                        <path d='M9 18v-6H5l7-7 7 7h-4v6H9z'></path>
                      </svg>
                      {comment.likes !== 0 && <span className='text-sm'>{comment.likes}</span>}
                    </button>
                    <div className='h-3 w-[1px] bg-gray-400'></div>
                    <button
                      onClick={() => handleDownvote(comment.id)}
                      className='flex h-6 text-md rounded-md items-center justify-between gap-0.5 text-gray-500 border-0'
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className={`fill-gray-500' h-4 w-4`}
                      >
                        <path d='M15 6v6h4l-7 7-7-7h4V6h6z'></path>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {comment.editing ? (
            <>
              <ReactQuill
                theme='snow'
                value={comment.content}
                onChange={(value) => setNewComment(comment.id, value)}
                className='w-full border rounded-md'
                placeholder='Add a comment...'
              />
              <button
                onClick={() => handleEdit(comment)}
                className='mt-2 px-4 py-2 bg-[#6B1E1E] text-md rounded-md border-0 text-white hover:bg-red-900'
              >
                POST
              </button>
            </>
          ) : (
            <div
              className='prose max-w-none bg-gray-100 p-4 rounded-md mt-2'
              dangerouslySetInnerHTML={{ __html: comment.content }}
            />
          )}

          {renderReplies(comment.replies, comment.id)}
          <ReplyBox parentId={comment.id} onReply={(replyContent) => handleAddReply(comment.id, replyContent)} />
        </div>
      ))}
    </div>
  );
};

export default CommentList;
